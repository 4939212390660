<template>
  <PageContentLayoutOnly role="region" class="is-center">
    <div class="level-left level-item title">{{ $t('helpCenter.title') }}</div>
    <div
      class="card"
      v-for="(d, i) in documents"
      :key="i"
      :style="{
        padding: '20px',
        marginBottom: '8px',
        display: 'flex',
        justifyContent: 'space-between'
      }"
    >
      <div>
        <h4 :style="{ margin: 0 }">{{ d.name }}</h4>
        <p :style="{ padding: '12px 0' }">{{ d.description }}</p>
      </div>

      <b-button
        @click="downloadPdf(d.helpCenterDocumentID)"
        class="button is-info is-light is-small is-outline is-rounded"
        size="is-small"
        >View Document</b-button
      >
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  name: 'HelpCenter',
  components: {
    PageContentLayoutOnly
  },

  data,
  methods,

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: {
        helpCenter: {
          title: 'Help Center'
        }
      }
    }
  }
}
</script>
