import { helpCenterDocumentStore } from '@/services/HelpCenterDocument/store'
import { base64toBlob, showPdf } from '@/services/blobutility'
import { notifyMessage, notifyProblem, notifyWarning } from '@/services/notify'

export const methods = {
  async downloadPdf(helpCenterDocumentID) {
    if (this.isDebug == true) console.debug('downloadPdf...' + helpCenterDocumentID)

    if (!helpCenterDocumentID || helpCenterDocumentID == undefined) {
      notifyProblem('There is a problem downloading this document.')
    }

    try {
      const params = {
        helpCenterDocumentID,
        asBase64: true,
        asRawBase64: true
      }

      await helpCenterDocumentStore.dispatch('getDocumentFile', {
        params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true) console.debug('base64pdf........................' + details)

            const contentType = 'application/pdf'
            const blob = base64toBlob(details, contentType)
            showPdf(blob)
            notifyMessage(`Successfully opened the help center document in a new tab.`)
            return
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem opening the help center document.')
    }
  },

  async reload() {
    console.debug('in reload....')
    this.loading = true

    await this.loadHelpCenterSubjects()

    this.loading = false
  },

  async loadHelpCenterSubjects() {
    this.loading = false

    try {
      await helpCenterDocumentStore.dispatch('getHelpCenterDocuentList').then(({ list }) => {
        this.documents = list
        this.loading = false
      })
    } catch (e) {
      this.loading = false
    }
    this.loading = false
  }
}
